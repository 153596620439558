import React from 'react'
import Idea from '../images/idea.svg'
import VideoCall from '../images/VideoCall.svg'
import './css/services.css'
import Mobile from '../images/Mobile.svg'
import MailAutomation from '../images/MailAutomation.svg'
import traffic from '../images/traffic.png'
import IOT from '../images/IOT.jpg'
import API from '../images/api.png'
import MetaTags from 'react-meta-tags'
export default function Services() {
    return (

        <div className = "First_body">
            <MetaTags>
            <title>Services | Veiyon Technologies</title>
            <meta name="description" content="Veiyon will provide various solutions for your idea, Veiyon will help you to grow your business using digital media" />
            </MetaTags>
            <div className="Inner_First_body">
                <div className='Help'>
                    <h3>What will we do for you ?</h3>
                </div>
                <div className ="First">
                    <div className="Idea">
                        <div id ="Idea_image">
                            <img src={Idea} ></img>
                        </div>
                        <div id="Idea_heading">
                            <h3> HAVE AN IDEA</h3>
                            <div id = "Idea_para">
                                <p>We will help you to make your idea into an great product with awesome New Technologies</p>    
                            </div>  
                        </div>     
                    </div>
                    <div className="VideoConf">
                        <div id ="VideoConf_image">
                            <img src={VideoCall} ></img>
                        </div>
                        <div id="VideoConf_heading">
                            <h3> UPGRADE TO NEW </h3>
                            <div id = "VideoConf_para">
                                <p>Already have an web or mobile application we will help you to upgrade your website with New Technologies like Video Calling, Chat bot, Email Automation, etc..</p>    
                            </div>  
                        </div>
                    </div>
                </div>
                <div className="Our_Services">
                    <h2>Our Services</h2>
                </div>
        <div className="SecondBody">
                
                <div className="AIBot">
                        <div className="AIBot_Content">
                            <h3>AI Chat Bot</h3>
                        </div>
                    </div>
                    <div className = "Mobile">
                        <img src={Mobile}></img>
                        <div className="Mobile_Content">
                            <h3>Mobile App</h3>
                        </div>
                    </div>
                    <div className="MailAutomation">
                        <img src={MailAutomation}></img>
                        <div className="MailAutomation_Content">
                            <h3>Email Automation</h3>
                        </div>
                    </div>
                    <div className="Videocall">
                        <img src={VideoCall}></img>
                        <div className="Videocall_Content">
                            <h3>Video Conferencing</h3>
                        </div>
                    </div>
                    <div className="Image_Processing">
                        <img src={traffic}></img>
                        <div className="Image_Processing_Content">
                            <h3>Machine Learning</h3>
                        </div>
                    </div>
                    <div className="Websitedev">
                        <img src="https://firebasestorage.googleapis.com/v0/b/veiyonwebsite.appspot.com/o/Category%2Fwebsite.svg?alt=media&token=83877787-4f6c-40db-9d1c-bd9f21975f4f"></img>
                        <div className="Image_Processing_Content">
                            <h3>Website Developement</h3>
                        </div>
                    </div>
                    
                    <div className="API">
                        <img src={API}></img>
                        <div className="Image_Processing_Content">
                            <h3>API creation</h3>
                        </div>
                    </div>
                    <div className="IOT">
                        <img src={IOT}></img>
                        <div className="Image_Processing_Content">
                            <h3>IOT Application</h3>
                        </div>
                    </div>
                    <div className="Alexa">
                        <img src="https://d3ogm7ac91k97u.cloudfront.net/en-US/alexa/branding/alexa-guidelines/brand-guidelines/the-alexa-logo.thumb.800.480.png?ck=1614891977"></img>
                        <div className="Image_Processing_Content">
                            <h3>Alexa Integration</h3>
                        </div>
                    </div>
                    <div className="GoogleAssistant">
                        <img height="130px" src="https://developers.google.com/assistant/assistant.png"></img>
                        <div className="Image_Processing_Content">
                            <h3>Google Assistant Integrtion</h3>
                        </div>
                    </div>
        </div>
            </div>
        </div>
    )
}
