import React from 'react'
import './css/popup.css'
import x from '../images/X.svg'
function Popup(props) {
    return (props.trigger)?(
        <div className="ProjectPopup">
            <div className="inner-ProjectPopup">
            <div className="Closebutton">
                <img id="close" src={x} alt="close" onClick={()=>props.setTrigger(false)}></img>
                
            </div>
            
            {props.children}
            </div>
        </div>
    ):""
}

export default Popup
