import {React} from 'react'
import rocket from '../images/rounded_space.svg'
import './css/home.css'
import grow from '../images/APPLICATION.svg'
import logo from '../images/veiyon_tech.svg'
import {Link} from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import MailIco from '../images/mailico.svg';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {Autoplay,Navigation} from 'swiper/core';
import "swiper/swiper.min.css";
import "./css/navigation.css"
import "./css/studio.css"
import PhoneInput from 'react-phone-number-input'
import {useState, useEffect} from 'react'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Popup from './popup.jsx'


SwiperCore.use([Autoplay,Navigation]);

function Home() {
    var [Cartcount , setCartcount] = useState([]);
    var URL = "https://ip.nf/me.json";
    const [Popupstate, setPopupState] = useState(false);
    const [PhoneNumber, setPhoneNumber] = useState();
    const [Mycountry , setMycountry] = useState({ip: ""});;
    const notify = () => toast.success("Response successfully submited.. 👍");
    const notifymail = () => toast.info("We will mail 📬 you as soon as possible 🕛");
    var haveadesign = "";

    useEffect(async() => {
        fetch(URL, {method: 'get'})
           .then((response) => response.json())
           .then((data) => {
               setMycountry({...data})  
           })
        })
    
    function formout(){
        var myname = document.getElementById('name_inp').value
        var projectname = document.getElementById('projectname_inp').value
        // var companyname = document.getElementById('companyname_inp').value
        var contact = PhoneNumber
        var projectidea = document.getElementById('projectidea_inp').value
        var design = "Veiyon FP"
        document.getElementById('Button_Attributes').disabled = true
        document.getElementById('Button_Attributes').style.opacity = 0.5
        
        if(projectname.includes("@") && projectname.includes(".") && myname !== "" && projectname !== "" &&  contact!=="" && projectidea !=="" && design!=="" && Cartcount !=="" && myname !== " " && projectname !== " " && contact!==" " && projectidea !==" " && design!==" " && Cartcount !==" "){
             
            axios.post(`https://opmrigktqj.execute-api.us-east-2.amazonaws.com/Test`,
            {
                "name":myname,
                "projectname":"Font Page",
                "companyname":"Veiyon FP",
                "email":projectname,
                "contact": contact,
                "idea":projectidea,
                "havedesign":"Veiyon FP",
                "list_of_need":Cartcount
            },
            {
                headers:{
                     'x-api-key': `${process.env.REACT_APP_MY_API_KEY}`
                }
            }
            ).then(response => {
                setPopupState(false)
                notify()
                setTimeout(function(){notifymail()}, 5200)    
            }
            );
        }
        else {
            window.alert("Please fill all the details")
            document.getElementById('Button_Attributes').disabled = false
            document.getElementById('Button_Attributes').style.opacity = 1

        }
       
    }
           
    return (
        <div className="home_container">
            <MetaTags>
            <title>Web and App development | Veiyon Technologies</title>
            <meta name="description" content="Veiyon will provide various solutions for your idea, Veiyon will help you to grow your business using digital media" />
            </MetaTags>
            <ToastContainer position="top-center"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick={false}
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover={false}/>
            <div className="mobile_view_1">
                <div className="body_first">
                    <div>
                    <h1 id="text11">Need to Develop an </h1>
                    <Swiper loop={true} autoplay={{"delay":2500, "disableOnInteraction":false}} style={{width:"80vw"}}>
                        <SwiperSlide>
                            <h1 id="text10" style={{fontSize:"22px",color:"#00CC59"}}>AI Application</h1>
                        </SwiperSlide>
                        <SwiperSlide>
                            <h1 id="text10" style={{fontSize:"22px",color:"#FF8800"}}>Web Application</h1>
                        </SwiperSlide>
                        <SwiperSlide>
                            <h1 id="text10" style={{fontSize:"22px",color:"#0011FF"}}>IOT Application</h1>
                        </SwiperSlide>
                        <SwiperSlide>
                            <h1 id="text10" style={{fontSize:"22px",color:"#0099FF"}}>Mobile Application</h1>
                        </SwiperSlide>
                        <SwiperSlide>
                            <h1 id="text10" style={{fontSize:"22px",color:"#F23232"}}>Desktop Application</h1>
                        </SwiperSlide>
                    </Swiper>
                    
                    </div>
                    <div className="body1">
                        <h1 id="body1_h2"> We are here to develop an attractive and productive websites for you</h1>
                    </div>
                </div>
            </div>
            <div className="mobile_view_2">
                <div className="body_second">
                    <Link to="/studio">
                        <button id="build_btn">
                            Start Building
                        </button>
                    </Link>
                    <div>
                    <button id="Estimate_btn" onClick={()=>
                    {
                        setPopupState(true)
                    }
                    }>
                            Get Estimation
                        </button>
                    </div>
                    <Swiper loop={true} autoplay={{"delay":3500,"disableOnInteraction":false}} style={{width:"85vw",fontFamily:"Lora",fontSize:"10px",color:"tomato"}} navigation={true}>

                    <SwiperSlide className="swiper">
                    <div className="swiper_Div">
                    <div className="swippingContent">
                       <h1>We will help you to develop your idea.</h1>
                       </div>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiper">
                    <div className="swiper_Div">
                        <div className="swippingContent">
                            <h1>We are here to develop your fantasy into more powerful application.</h1>
                        </div>
                       
                    </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiper">
                    <div className="swiper_Div">
                        <div className="swippingContent">
                            <h1>We will also help you in Digital Marketing</h1>
                        </div>
                       
                    </div>
                    </SwiperSlide>
                    </Swiper>
                    <div className="secondbox">
                        <div className="secondbox_cont">
                            <h1 id="promote_cont">Reach your customer very faster than you think, by promoting your products online...</h1>
                        </div>
                    </div>
                </div>
                
            </div>
            {/* <Popup trigger={true} setTrigger={setPopupState}>
                <h1>Hi</h1>
            </Popup> */}
    <Popup trigger={Popupstate} setTrigger={setPopupState}>
        <div className="popup-content">
            <div id='YourName'>
                <h4>Your Name </h4>
                <div id="inp1">
                <input id="name_inp"></input>
            </div>
        </div>
        <div id='projectName'>
            <h4>Email </h4>
            <div id="inp2">
                <input type="email" id="projectname_inp"></input>
            </div>
        </div>
        {/* <div id = "companyName">
            <h4>Company Name </h4>
            <div id = "inp3">
                <input id="companyname_inp"></input>
            </div>
        </div> */}
        <div id="Contact">
            <h4>Mobile Number </h4>
            <div id="inp4">
                <PhoneInput
                    defaultCountry={Mycountry.ip.country_code}
                    value={PhoneNumber}
                    onChange={setPhoneNumber}/>
            </div>
        </div>
        <div>
            <h4>What do you need ?</h4>
            <div id = "CheckBox">
            <div style={{display:"flex",alignItems:"center"}}>
                <input type="checkbox"
                onClick={async()=>{
                    if ( Cartcount.includes('Website')){
                        var index = Cartcount.indexOf('Website')
                        await Cartcount.splice(index, 0);   
                        await setCartcount(currentCount => currentCount.filter((Cartcount, i) => i !== index));
                    }
                    else{
                        setCartcount([...Cartcount,'Website'])
                    }
                }}></input>
                <p3 id="checkbox_con">Website</p3></div>
                <div style={{display:"flex",alignItems:"center"}}>
                <input onClick={async()=>{
                    if ( Cartcount.includes('Android App')){
                        var index = Cartcount.indexOf('Android App')
                        await Cartcount.splice(index, 0);   
                        await setCartcount(currentCount => currentCount.filter((Cartcount, i) => i !== index));
                        
                    }
                    else{
                        setCartcount([...Cartcount,'Android App'])
                    }
                }} type="checkbox"></input>
                <p3 id="checkbox_con">Android App</p3>
                </div>
                <div style={{display:"flex",alignItems:"center"}}>
                <input onClick={async()=>{
                    if (Cartcount.includes('IOS App')){
                        var index = Cartcount.indexOf('IOS App')
                        await Cartcount.splice(index, 0);   
                        await setCartcount(currentCount => currentCount.filter((Cartcount, i) => i !== index));
                        
                    }
                    else{
                        setCartcount([...Cartcount,'IOS App'])
                    }
                }} type="checkbox"></input>
                <p3 id="checkbox_con">IOS App</p3>
                </div>
                <div style={{display:"flex",alignItems:"center"}}>
                <input 
                onClick={async()=>{
                    if (Cartcount.includes('UI Design')){
                        var index = Cartcount.indexOf('UI Design')
                        await Cartcount.splice(index, 0);   
                        await setCartcount(currentCount => currentCount.filter((Cartcount, i) => i !== index));
                    }
                    else{
                        setCartcount([...Cartcount,'UI Design'])
                    }
                }}
                type="checkbox"></input>
                <p3 id="checkbox_con">UI Design</p3>
                </div>
            </div>    
        </div>                 
        <div id="projectIdea">
            <h4>Explain about your Project / Idea /any extra features you need  </h4>
            <div id = "inp6">
                <textarea id="projectidea_inp" cols="5"></textarea>
            </div>
        </div>
        {/* <h4>Do You have any Designs related to your project? </h4>
            <input type="radio" id="Yes" name="option" onClick={()=>haveadesign = "Yes"}></input>
            <label >Yes</label>
            <input type="radio" id="No" name="option" onClick={()=>haveadesign = "No"}></input>
            <label >No</label> */}
            <div className="Submit_Button">
                <button style={{cursor:'pointer'}} 
                id="Button_Attributes" 
                onClick={()=>
                    formout()
                }>Submit</button>
            </div>                     
        </div>
    </Popup>
            <div className="home_resize">
            <div className="bg">
            <div className="home_container1">
                    <div id = 'Top_content'>
                        <h1 id="text0">Need to Develop an</h1>
                        <Link id = "studio" to="/studio">
                        <button id="Top_button">Start your project</button></Link>
                        <button id="GetEstimation" onClick={()=>{
                            setPopupState(true)
                        }}>Get Estimation</button>
                    </div>
                    <div id = "imageofrocket">
                        <img alt="rocket" src={rocket} 
                        ></img>
                    </div>
                    </div>
            </div>
    </div>
    
            <div className="home_container2">
                <div className="Inner">
                    <div id="Inner_image">
                        <img alt="grow" width="100%" src={grow}></img>
                    </div>
                    <div id="Inner_content">
                        <h1 id="Inner_text1">Make your Fantasy into Reality</h1>
                        <h3 id="text1"></h3>
                        <Link id = "studio" to="/studio">
                        <button  id="Inner_button">Get started</button></Link>
                    </div>
                </div>
            </div>
            <div className="address">
                <div className="mail">
                    <img alt="veiyon_mail" src={MailIco} width="25px"></img>
                    <a style={{textDecoration:"none", color:"rgb(18, 18, 97)"}} id="mailadd" href="mailto:veiyontech@gmail.com">veiyontech@gmail.com</a>
                    
                </div>
                <div className="address_nav">
                    <Link id = "services" to="/services">
                    <h3>Our Services</h3></Link>
                    <Link id = "studio" to="/studio">
                    <h3>Studio</h3></Link>
                </div>
                <div className="bottom_logo">
                    <img alt="veiyon technologies" width="110px" src={logo}></img>
                </div>
            </div>
            <div className="line">
            </div>
            <footer >
                <div className="copyright">
                    <p> © Copyright Veiyon Technologies 2021 | All Rights Reserved.</p>
                </div>
            </footer>
            
        </div>
    )
}

export default Home
