import React from 'react'
import './css/mobilenav.css'
import x from '../images/X.svg'
function MobileNav(props) {
    return (props.trigger)?(
        <div className="MobilePopup">
            <div className="inner-MobilePopup">
            <img src="web" className="close_icon" src={x} width="25px" height="35px" onClick={()=>props.setTrigger(false)}></img>
            {props.children}
            </div>
        </div>
    ):""
}

export default MobileNav