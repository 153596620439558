import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import Home from './components/home';
import Services from './components/services';
import Studio from './components/studio';
import Navbar from './components/navbar';
function App() {
  console.warn = ()=> {}
  // console.log = ()=>{}
  return (
    <Router>
      <div className="App">
      <Navbar/>
        <Switch>
        <Route path="/" exact component ={Home}/>
        <Route path="/services" exact component ={Services}/>
        <Route path="/studio" exact component ={Studio}/>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
