import {React, useState, useEffect} from 'react'
import './css/studio.css'
import Cart from '../images/cart.svg'
import firebaseConfig from './db'
import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import Popup from './popup.jsx'
import LogoutButton from './LogoutBtn.jsx';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import axios from 'axios';
import MetaTags from 'react-meta-tags'
import Loadgif from '../images/Veiyon-gif.gif'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();
const db = firebase.firestore();    
const auth = firebase.auth();

export default function Studio() {
  
    var [Communication,setCommunication]= useState([])
    var [SocialMedia , setSocialMedia] = useState([])
    var [Notification, setNotification] = useState([])
    var [Login , setLogin] = useState([]);
    var [Cartcount , setCartcount] = useState([]);
    var [Category, setCategory] = useState([]);
    var [Selected, setSelected] = useState([]);
    const [user, setuser] = useState(null)
    const [LoginPopupstate,setLoginPopupState] = useState(false);
    var [LogoutButton, setLogoutButton]= useState('none');
    var URL = "https://ip.nf/me.json";
    const [Popupstate,setPopupState] = useState(false);
    const [PhoneNumber, setPhoneNumber] = useState();
    const [Mycountry , setMycountry] = useState({ip: ""});
    const [Loading , setLoading] = useState(false);
    
    
    const notify = () => toast.success("Response successfully submited.. 👍");
    const notifymail = () => toast.info("We will mail 📬 you as soon as possible 🕛");
    
    useEffect(async() => {
        setLoading(true)
        fetch(URL, {method: 'get'})
           .then((response) => response.json())
           .then((data) => {
               setMycountry({...data})
               
           })
        
        auth.onAuthStateChanged(async (user) => {
            setuser(user)
        })

        await db.collection('Category').onSnapshot((snapshot) =>{
            
            const data = snapshot.docs.map((doc) => ({
                id:doc.id,
                ...doc.data()
            }))
            setCategory(data)
            })
            
        await db.collection('Communication').onSnapshot((snapshot) =>{
            const data = snapshot.docs.map((doc) => ({
                id:doc.id,
                ...doc.data()
            }))
            setCommunication(data)
            })
        
        await db.collection('Notification').onSnapshot((snapshot) =>{
            const data = snapshot.docs.map((doc) => ({
                id:doc.id,
                ...doc.data()
            }))
            setNotification(data)
            })
        
        await db.collection('Socialmedia').onSnapshot((snapshot) =>{
            const data = snapshot.docs.map((doc) => ({
                id:doc.id,
                ...doc.data()
            }))
            setSocialMedia(data)
                })
        
        await db.collection('Login').onSnapshot((snapshot) =>{
                const data = snapshot.docs.map((doc) => ({
                    id:doc.id,
                    ...doc.data()
                }))
                setLogin(data)
                setTimeout(function(){ setLoading(false); }, 2000);
                })
        }, []) 
    
        var haveadesign = "";

        function formout(){

            var myname = document.getElementById('name_inp').value
            var projectname = document.getElementById('projectname_inp').value
            var companyname = document.getElementById('companyname_inp').value
            var contact = PhoneNumber
            
            var projectidea = document.getElementById('projectidea_inp').value
            var design = haveadesign
            document.getElementById('Button_Attributes').disabled = true
            document.getElementById('Button_Attributes').style.opacity = 0.5
            
            if(myname !== "" && projectname !== "" && companyname !=="" && contact!=="" && projectidea !=="" && design!=="" && Cartcount !=="" && myname !== " " && projectname !== " " && companyname !==" " && contact!==" " && projectidea !==" " && design!==" " && Cartcount !==" "){
                 
                axios.post(`https://opmrigktqj.execute-api.us-east-2.amazonaws.com/Test`,
                {
                    "name":myname,
                    "projectname":projectname,
                    "companyname":companyname,
                    "email":user.email,
                    "contact": contact,
                    "idea":projectidea,
                    "havedesign":design,
                    "list_of_need":Cartcount
                },
                {
                    headers:{
                         'x-api-key': `${process.env.REACT_APP_MY_API_KEY}`
                    }
                }
                ).then(response => {
                    setPopupState(false)
                    analytics.logEvent("Form submit")
                    notify()
                    setTimeout(function(){notifymail()}, 5200)    
                }
                );
            }
            else {
                window.alert("Please fill all the details")
                document.getElementById('Button_Attributes').disabled = false
                document.getElementById('Button_Attributes').style.opacity = 1

            }
           
        }
    return (
        <div className="App">
           
            <MetaTags>
            <title>Veiyon Studio - Configure your project needs | Veiyon Technologies</title>
            
            <meta name="description" content="Veiyon studio helps you to configure your application based on your needs, Veiyon studio provides various different solutions to configure for your projects." />
            </MetaTags>
            <div className="App_Body" >
                <div className="SignOutButton">
                    <button style={{display:LogoutButton}} onClick={()=> 
                        {
                            auth.signOut()
                            analytics.logEvent('logout')
                            setLogoutButton("none")    
                        }}>
                        LogOut
                    </button>
                </div>
                    <div className ="Cartlogo">
                        
                        <div className="Estimation_button">
                            <button disabled={Selected.length !== 0?false:true} onClick={()=> 
                            
                                {
                                    if (user != null){
                                        setLogoutButton('flex')
                                        setPopupState(true)
                                    }
                                    else{
                                        setLoginPopupState(true)
                                    }
                                }
                            
                            } style={{backgroundColor:'sandybrown',display:'flex',opacity:Selected.length !== 0?1:0.4}}>Get Estimation</button>
                            <ToastContainer position="top-center"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick={false}
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover={false}/>
                        </div>
                        <Popup trigger={LoginPopupstate} setTrigger={setLoginPopupState}>
                            <div className="LoginButton_Container">
                            <div id="googlebutton">

                                    <div id="googlelogin" onClick={()=>{
                                        const googleProvider = new firebase.auth.GoogleAuthProvider()
                                        auth.signInWithPopup(googleProvider).then((res) => {
                                            console.log(user)
                                            setuser(user)
                                            setLogoutButton('flex')
                                            setLoginPopupState(false)
                                            setPopupState(true)
                                            analytics.setUserId(user.uid)                
                                            analytics.logEvent('login')
                                          }).catch((error) => {
                                            console.log(error.message)
                                          })
                                    }}>
                                        <img style={{paddingLeft:"10px"}} width="30px" height="30px" src="https://developers.google.com/identity/images/g-logo.png"></img>
                                        <p style={{paddingLeft:"10px"}}>Login with Google</p>
                                    </div>
                                </div>

                                <div id="facebookbutton">
                                    <div id="facebooklogin" onClick={()=>
                                    {
                                        const fbProvider = new firebase.auth.FacebookAuthProvider()
                                        auth.signInWithPopup(fbProvider).then((res) => {
                                            setuser(user)
                
                                            setLoginPopupState(false)
                                            setLogoutButton('flex')
                                            setPopupState(true)
                                            analytics.setUserId(user.uid)
                                            analytics.logEvent('login')
                                          }).catch((error) => {
                                            console.log(error.message)
                                          })
                                    }}>
                                        <img style={{paddingLeft:"10px"}} width="30px" src="https://facebookbrand.com/wp-content/uploads/2019/10/Copy-of-facebook-app.svg"></img>
                                        <p style={{paddingLeft:"10px"}}>Login with Facebook</p>
                                    </div>
                                </div>
                            </div>
                        </Popup>

                        <img id="slider" src={Cart} width ="40px"></img>
                        <h4 id="CartTotalCount">{Cartcount.length}</h4>
                    </div>  
                    <Popup trigger={Popupstate} setTrigger={setPopupState}>
                            <div className="popup-content">
                                <div id='YourName'>
                                    <h4>Your Name </h4>
                                    <div id="inp1">
                                        <input id="name_inp"></input>
                                    </div>
                                </div>
                                <div id='projectName'>
                                    <h4>Project Name </h4>
                                    <div id="inp2">
                                        <input id="projectname_inp"></input>
                                    </div>
                                </div>
                                <div id = "companyName">
                                    <h4>Company Name </h4>
                                    <div id = "inp3">
                                        <input id="companyname_inp"></input>
                                    </div>
                                </div>
                                <div id="Contact">
                                    <h4>Mobile Number </h4>
                                    <div id="inp4">
                                        <PhoneInput
                                            defaultCountry={Mycountry.ip.country_code}
                                            value={PhoneNumber}
                                            onChange={setPhoneNumber}/>
                                    </div>
                                </div>
                                

                                <div id="projectIdea">
                                    <h4>Explain about your Project / Idea /any extra features you need  </h4>
                                    <div id = "inp6">
                                        <textarea id="projectidea_inp" cols="5"></textarea>
                                    </div>
                                </div>
                                <h4>Do You have any Designs related to your project? </h4>
                                    <input type="radio" id="Yes" name="option" onClick={()=>haveadesign = "Yes"}></input>
                                        <label >Yes</label>
                                    <input type="radio" id="No" name="option" onClick={()=>haveadesign = "No"}></input>
                                        <label >No</label>
                                <div className="Submit_Button">
                                    <button style={{cursor:'pointer'}} id="Button_Attributes" 
                                    onClick={()=>
                                       
                                        // document.getElementById('Button_Attributes').style.opacity = 0.5,
                                        formout()
                                    }>Submit</button>
                                </div>
                                
                            </div>
                        </Popup>
                         <div className="loading" style={{display:Loading?"flex":"none"}}>
               <img src={Loadgif}></img>
            </div>
                <div className="Add-on" style={{display:Loading?"none":"unset"}}>
                    <div id = "addon_heading"><h3 id="whatyouneed">What do you need ?</h3></div>
                        <div className="Items">
                           {
                            Category && Category.map((allitems)=>{
                                   return(
                                       <div key={allitems.id}>
                                            <div className="Login_Items">
                                                <div onClick={async () => {
                                                    if (Cartcount.includes(allitems.Name)){
                                                        var index = Cartcount.indexOf(allitems.Name)
                                                        await Cartcount.splice(index, 0);   
                                                        await setCartcount(currentCount => currentCount.filter((Cartcount, i) => i !== index));
                                                        document.getElementById(allitems.id).checked = false;
                                                        
                                                        var selectedindex = Selected.indexOf(allitems.Name);
                                                        Selected.splice(selectedindex, 0)
                                                        setSelected(Selected => Selected.filter((Selected, i) => i !== selectedindex));
                                                    }
                                                    else {
                                                        setCartcount([...Cartcount, allitems.Name]);
                                                        setSelected([...Selected, allitems.Name]);
                                                        document.getElementById(allitems.id).checked = true;
                                                    }
                                                }
                                                }>
                                                    <div id="studioimage">
                                                    <div className="cartcheckbox_container">
                                                            <div className="cart_check">
                                                                <input className="checkbox_class"  id={allitems.id} type="checkbox"></input>
                                                            </div>
                                                            <img  src={allitems.Image} alt={allitems.Name} height="70px" width="80px"></img>
                                                        </div>
                                                        </div>
                                                    <div id="login_text">
                                                        <h3>{allitems.Name}</h3>
                                                    </div>
                                                </div>
                                            </div>

                                       </div>
                                   )
                               })
                           }
                           </div>
                        <h3 id = "addon_heading">Login</h3>
                        <div className="Items">
                           {
                            Login && Login.map((allitems)=>{
                                
                                   return(
                                       <div key={allitems.id} >
                                            <div className="Login_Items" >
                                                
                                                <div onClick={() =>  {
                                                    
                                                    if (Cartcount.includes(allitems.Name)){
                                                        var index = Cartcount.indexOf(allitems.Name)
                                                        Cartcount.splice(index, 0);  
                                                        setCartcount(currentCount => currentCount.filter((Cartcount, i) => i !== index));
                                                        document.getElementById(allitems.id).checked = false;
                                                    }
                                                    else {
                                                        setCartcount([...Cartcount, allitems.Name]);
                                                        document.getElementById(allitems.id).checked = true;
                                                    }
                                                    
                                                }}>
                                                    <div id="studioimage" >
                                                        <div className="cartcheckbox_container">
                                                            <div className="cart_check">
                                                                <input className="checkbox_class"  id={allitems.id} type="checkbox"></input>
                                                            </div>
                                                            <img  src={allitems.Image} alt={allitems.Name} height="60px" width="60px"></img>
                                                        </div>
                                                    </div>
                                                    <div id="login_text">
                                                        <h3>{allitems.Name}</h3>
                                                    </div>
                                                </div>
                                            </div>

                                       </div>
                                   )
                               })
                           }
                           </div>
                    <div>
                        <h3 id = "addon_heading">Communication</h3>
                        <div className="Items">
                           {
                            Communication && Communication.map((allitems)=>{
                                   return(
                                       <div key={allitems.id}>
                                            <div className="Login_Items">
                                                <div onClick={() => {

                                                    if (Cartcount.includes(allitems.Name)){
                                                        var index = Cartcount.indexOf(allitems.Name)
                                                        Cartcount.splice(index, 0);   
                                                        setCartcount(currentCount => currentCount.filter((Cartcount, i) => i !== index));
                                                        document.getElementById(allitems.id).checked = false;
                                                        
                                                    }
                                                    else {
                                                        setCartcount([...Cartcount, allitems.Name]);
                                                        document.getElementById(allitems.id).checked = true;
                                                        
                                                    }
                                                }}>
                                                    <div id="studioimage">
                                                    <div className="cartcheckbox_container">
                                                            <div className="cart_check">
                                                                <input className="checkbox_class"  id={allitems.id} type="checkbox"></input>
                                                            </div>
                                                            <img  src={allitems.Image} alt={allitems.Name} height="60px" width="60px"></img>
                                                        </div>
                                                        </div>
                                                    <div id="login_text">
                                                        <h3>{allitems.Name}</h3>
                                                    </div>
                                                </div>
                                            </div>

                                       </div>
                                   )
                               })
                           }
                           </div>
                        <h3 id = "addon_heading">Notifications</h3>
                        <div className="Items">
                           {
                            Notification && Notification.map((allitems)=>{
                                   return(
                                       <div key={allitems.id}>
                                            <div className="Login_Items">
                                                <div className="Push_Notification" onClick={() => {
                                                    if (Cartcount.includes(allitems.Name)){
                                                        var index = Cartcount.indexOf(allitems.Name)
                                                        Cartcount.splice(index, 0);   
                                                        setCartcount(currentCount => currentCount.filter((Cartcount, i) => i !== index));
                                                        document.getElementById(allitems.id).checked = false;
                                                        
                                                    }
                                                    else {
                                                        setCartcount([...Cartcount, allitems.Name]);
                                                        document.getElementById(allitems.id).checked = true;
                                                        
                                                    }
                                                }}>
                                                    <div id="studioimage">
                                                    <div className="cartcheckbox_container">
                                                            <div className="cart_check">
                                                                <input className="checkbox_class"  id={allitems.id} type="checkbox"></input>
                                                            </div>
                                                            <img  src={allitems.Image} alt={allitems.Name} height="60px" width="60px"></img>
                                                        </div>
                                                        </div>
                                                    <div id="login_text">
                                                        <h3>{allitems.Name}</h3>
                                                    </div>
                                                </div>
                                            </div>

                                       </div>
                                   )
                               })
                           }
                           </div>
                        <h3 id = "addon_heading">Link SocialMedia</h3>
                        <div className="Items">
                            {
                                SocialMedia && SocialMedia.map((allitems)=>{
                                   return(
                                       <div key={allitems.id}>
                                            <div className="Login_Items">
                                                <div className="Push_Notification" onClick={() => {
                                                    if (Cartcount.includes(allitems.Name)){
                                                        var index = Cartcount.indexOf(allitems.Name)
                                                        Cartcount.splice(index, 0);   
                                                        setCartcount(currentCount => currentCount.filter((Cartcount, i) => i !== index));
                                                        document.getElementById(allitems.id).checked = false;
                                                        
                                                    }
                                                    else {
                                                        setCartcount([...Cartcount, allitems.Name]);
                                                        document.getElementById(allitems.id).checked = true;
                                                        
                                                    }
                                                }}>
                                                    <div id="studioimage">
                                                    <div className="cartcheckbox_container">
                                                            <div className="cart_check">
                                                                <input className="checkbox_class"  id={allitems.id} type="checkbox"></input>
                                                            </div>
                                                            <img  src={allitems.Image} alt={allitems.Name} height="60px" width="60px"></img>
                                                        </div>
                                                        </div>
                                                    <div id="login_text">
                                                        <h3>{allitems.Name}</h3>
                                                    </div>
                                                </div>
                                            </div>

                                       </div>
                                   )
                               })
                           }
                           </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
