import logo from '../images/veiyon_tech.svg';
import './css/navbar.css';
import Menu from '../images/menu.png'
import {React , useState} from 'react'
import MobileNav from './mobilenav';
import { Link } from 'react-router-dom';

function Navbar(){
    const [Popupstate,setPopupState] = useState(false);
    return (
       
        <div className='navbar_container'>
            <img id="logo" src={logo} className="App-logo" alt="logo" />
            <div className="NavLeft_container">
                <Link id="home" to="/">
                    <h2 >Home</h2>
                </Link>
                <Link id = "services" to="/services">
                    <h2 >Services</h2>
                </Link>
                <Link id = "studio" to="/studio">
                    <h2 >Studio</h2>
                </Link>
            </div>
            
            <div className="Mobile_Nav">
                <img onClick={()=>setPopupState(true)} width="35px" src={Menu}></img>
            </div>
            <MobileNav trigger={Popupstate} setTrigger={setPopupState}>
                <div className ="Mobile_Nav_item">
                <Link id="items" to="/" onClick={()=>setPopupState(false)}>
                    <h2 > Home</h2>
                </Link>
                <Link id = "items" to="/services" onClick={()=>setPopupState(false)}>
                    <h2 >Services</h2>
                </Link>
                <Link id = "items" to="/studio" onClick={()=>setPopupState(false)}>
                    <h2 >Studio</h2>
                </Link>
                </div>
            </MobileNav>
        </div>
    )
}
export default Navbar;