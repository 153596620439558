// const firebaseConfig = {
//     apiKey: "AIzaSyDNiAGdCJIT50DX1g9JJM3v7EZwpC42OKY",
//     authDomain: "veiyon.com",
//     databaseURL: "https://veiyonwebsite-default-rtdb.firebaseio.com",
//     projectId: "veiyonwebsite",
//     storageBucket: "veiyonwebsite.appspot.com",
//     messagingSenderId: "286049230360",
//     appId: "1:286049230360:web:291dbd3dde332d82f06d6d",
//     measurementId: "G-X2M555CRRR"
//   };
//   console.log(process.env.REACT_APP_APP_ID)
// export default firebaseConfig;

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "veiyon.com",
  databaseURL: process.env.REACT_APP_DATABASE,
  projectId: "veiyonwebsite",
  storageBucket: process.env.REACT_APP_STORAGE,
  messagingSenderId: process.env.REACT_APP_MESSAGE_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

export default firebaseConfig;